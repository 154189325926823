import React, { useEffect, useState } from 'react';
import { onUpdateProductDesign } from '../../Actions/ProductAction'
import Calendar from 'react-calendar';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosWarning, } from "react-icons/io";
import moment from "moment-timezone";

const ProductDesign = ({ mainLoader }) => {

    const dispatch = useDispatch()
    const state = useSelector(state => state.product.data)
    const design = useSelector(state => state.product.data.design)
    const businessData = useSelector(state => state.business.data)
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState([]);
    const [selectedTime, setSelectedTime] = useState("");
    const [warning, setWarning] = useState({
        msg: "",
        type: ""
    });


    const handleToggle = (e) => {
        const { name, checked } = e.target
        if (name === 'imageEnabled') {
            if (state.logo === "" && !design.imageEnabled) {
                setWarning({
                    type: "logo",
                    msg: "Please add logo data from General Tab to enable it"
                })
                handleWarning()
            } else {
                dispatch(onUpdateProductDesign({ ...design, [name]: checked }))
            }
        } else if (name === 'nameEnabled') {
            if (state.name === "" && !design.nameEnabled) {
                setWarning({
                    type: "name",
                    msg: "Please add name data from General Tab to enable it"
                })
                handleWarning()
            } else {
                dispatch(onUpdateProductDesign({ ...design, [name]: checked }))
            }
        } else {
            if (state.description === "" && !design.descriptionEnable) {
                setWarning({
                    type: "description",
                    msg: "Please add description data from General Tab to enable it"
                })
                handleWarning()
            } else {
                dispatch(onUpdateProductDesign({ ...design, [name]: checked }))
            }
        }
    }

    const handleWarning = () => {
        setTimeout(() => {
            setWarning({ type: "", msg: "" });
        }, 5000);
    };

    const dateOnChange = (selectedDate) => {
        setDate(selectedDate);
    };

    const handleSelectedTime = (selectTime) => {
        setSelectedTime(selectTime)
    };

    const getTimeData = (openingHours) => {
        if (openingHours?.length > 0) {
            let options = { weekday: 'long' };
            let selectedDay = date.toLocaleDateString('en-US', options);
            const currentDay = openingHours.filter(ele => ele.day.toLowerCase() === selectedDay.toLowerCase());
            let from = currentDay[0].fromTime + currentDay[0].fromMeridiem;
            let to = currentDay[0].toTime + currentDay[0].toMeridiem;
            let gap = 30
            if (currentDay[0].enable === true) {
                let timeArray = generateTimeSlots(from, to, gap)
                setTime(timeArray)
            } else {
                setTime([])
            }
        }
    };

    useEffect(() => {
        getTimeData(state.openingHours)
    }, [state, date])

    function generateTimeSlots(startTime, endTime, interval) {
        const timeSlots = [];
        let currentTime = ""
        let endTimeMoment = ""
        if (startTime.includes("AM") || startTime.includes("PM")) {
            currentTime = moment(startTime, "hh:mm A");
            endTimeMoment = moment(endTime, "hh:mm A");
            if (currentTime > endTimeMoment) {
                endTimeMoment = moment(endTime, "HH:mm").add(1, "day");
            }
            while (currentTime <= endTimeMoment) {
                timeSlots.push(currentTime.format("hh:mm A"));
                currentTime = currentTime.add(interval, "minutes");
            }
        } else {
            currentTime = moment(startTime, "HH:mm");
            endTimeMoment = moment(endTime, "HH:mm")
            if (currentTime > endTimeMoment) {
                endTimeMoment = moment(endTime, "HH:mm").add(1, "day");
            }

            while (currentTime <= endTimeMoment) {
                timeSlots.push(currentTime.format("HH:mm"));
                currentTime = currentTime.add(interval, "minutes");
            }
        }

        return timeSlots;
    }

    return (
        <>
            <div className="designElem">
                <div className='designElem-left'>
                    <div className='toggleBtn'>
                        <ul>
                            <li>
                                <h6>Image</h6>
                                <label className="switch">
                                    <input
                                        name="imageEnabled"
                                        type="checkbox"
                                        checked={design.imageEnabled}
                                        onChange={(e) => handleToggle(e)}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </li>
                            <div style={{ color: "red", fontFamily: "roboto" }}>
                                {warning.type === "logo" && state.logo === "" ? <><IoIosWarning />{warning.msg} </> : ""}
                            </div>
                            <li>
                                <h6>Name</h6>
                                <label className="switch">
                                    <input
                                        name="nameEnabled"
                                        type="checkbox"
                                        checked={design.nameEnabled}
                                        onChange={(e) => handleToggle(e)}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </li>
                            <div style={{ color: "red", fontFamily: "roboto" }}>
                                {warning.type === "name" && state.name === "" ? <><IoIosWarning />{warning.msg} </> : ""}
                            </div>
                            <li>
                                <h6>Description</h6>
                                <label className="switch">
                                    <input
                                        name="descriptionEnable"
                                        type="checkbox"
                                        checked={design.descriptionEnable}
                                        onChange={(e) => handleToggle(e)}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </li>
                            <div style={{ color: "red", fontFamily: "roboto" }}>
                                {warning.type === "description" && state.description === "" ? <><IoIosWarning />{warning.msg} </> : ""}
                            </div>
                        </ul>
                    </div>
                </div>
                <div className='designElem-right' style={{ position: "relative" }}>
                    {mainLoader ?
                        <div className="design_loader" style={{ transform: "translate(-50%, -50%)" }}>
                            <i
                                className="fa fa-spinner fa-spin mx-1"
                                style={{ fontSize: "20px", }}
                            />
                        </div>
                        :
                        <div className='designPreview_wrapper'>
                            <div className="designPreview">
                                <div className='prevGroup'>
                                    <div className='prevGroup-main'>
                                        <div className='userDesc'>
                                            {design.imageEnabled ?
                                                <div className='userDesc-img'><img src={state.logo} alt="" /></div>
                                                : ""
                                            }
                                            <div className='userDesc-txt'>
                                                {design.nameEnabled ?
                                                    < h2 className="pt-2">{state.name}</h2>
                                                    : ""
                                                }
                                                <div className='skillGroup'>
                                                    {design.descriptionEnable ?
                                                        <p>{state.description}</p>
                                                        : ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='prevGroup mt-3'>
                                    <div className='prevGroup-main'>
                                        <div className='prevCalendar'>
                                            <div className='row'>
                                                <div className='col-lg-7'>
                                                    <h6 className='text-center'>Select a Date and Time</h6>

                                                    <div className='prevCalendar-wrap'>
                                                        <Calendar
                                                            onChange={dateOnChange} value={date}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-5'>
                                                    <div className='timeSelect'>
                                                        <ul>
                                                            {time ?
                                                                time.map((curlEle, index) => {
                                                                    if (curlEle === selectedTime) {
                                                                        return <li className='timeBlock selected'>
                                                                            <div className='row'>
                                                                                <div className='col-6'><button>{curlEle}</button></div>
                                                                                <div className='col-6'><button className='btnBlue'>Submit</button></div>
                                                                            </div>
                                                                        </li>
                                                                    } else {
                                                                        return <li className='timeBlock' key={index} onClick={() => handleSelectedTime(curlEle)}>{curlEle}</li>
                                                                    }
                                                                })
                                                                : ""}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="FooterprevGroup mt-2">
                                <div className="prevGroup-main p-3">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <footer className="d-flex justify-content-between">
                                                <span
                                                    style={{
                                                        fontSize: "13px",
                                                        color: '#000',
                                                        fontWeight: '600',
                                                        paddingLeft: '20px',
                                                    }}
                                                >© 2023, All Rights Reserved to FastPaid</span>
                                                <ul className="d-flex justify-content-evenly">
                                                    {businessData.socialMedia.facebook ? <li className="me-3"><a><i className="fa-brands fa-facebook" style={{ color: "#5c5c5d", fontSize: "20px" }}></i></a></li> : ""}
                                                    {businessData.socialMedia.youtube ? <li className="me-3"><a><i className="fa-brands fa-youtube"
                                                        style={{ color: "#5c5c5d", fontSize: "20px" }}></i></a></li> : ""}
                                                    {businessData.socialMedia.instagram ? <li className="me-3"><a><i className="fa-brands fa-instagram"
                                                        style={{ color: "#5c5c5d", fontSize: "20px" }}></i></a></li> : ""}
                                                    {businessData.socialMedia.twitter ? <li className="me-3"><a> <i className="fa-brands fa-twitter" style={{ color: "#5c5c5d", fontSize: "20px" }}></i></a></li> : ""}
                                                </ul>
                                            </footer>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default ProductDesign;